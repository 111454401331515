// src/pages/produktion.js

import React from "react"
import data from "../data/yaml/production_page.yaml"
import HeadMetadata from "../components/headMetadata"
import HeaderBar from "../components/headerBar"
import Banner from "../components/banner"
import Service from "../components/service"
import Work from "../components/work"
// import Casestudies from "../components/casestudies"
import Contact from "../components/contact"
import Footer from "../components/footer"


export default () => {
  return (
    <>
      <HeadMetadata {...data.metadata} />
      <HeaderBar />
      <Banner {...data} />
      <Service {...data} />
      {/* <Casestudies isPlaying={false} currentSlide="2" /> */}
      <Work pageId="production" {...data.image} />
      <Contact headline = {data.contact.headline}/>
      <Footer />
    </>
  )
  }
